'use client'

import { receiveNotification, publishSharedData } from 'bossjob-remote/dist/clientStorage'
import { getCookie, removeCookie } from 'helpers/cookies'
import { getCountryId, getLang, getLanguageId } from 'helpers/country'
import { redirectUrlKey } from 'helpers/globalKey'
import { routes } from 'helpers/utilities'
import { useRouter } from 'next/navigation'

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { displayNotification } from 'store/actions/notificationBar/notificationBar'
import { fetchUserSetting } from 'store/services/swtichCountry/userSetting'
import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { getProfileCompleteStep } from '../CompleteProfileCard/utils'
import { traceDistinationAbility } from '../adjust'

const defaultLoginCallBack = async (data: any, isPhone = false, router = undefined) => {
  const searchParams = new URLSearchParams(location.href)
  const langKey = getLang()
  const redirect = searchParams.get(redirectUrlKey)
  const defaultRedirectPage = Array.isArray(redirect) ? redirect[0] : redirect
  const redirectPage = sessionStorage.getItem('redirectPage')
  const isChatRedirect = sessionStorage.getItem('isChatRedirect')
  const pathname = location.pathname
  const newData = data || getCookie('user')
  const redirectPath = getCookie('__redirect')
  const chatJobId = sessionStorage.getItem('chatJobId') || ''
  sessionStorage.removeItem('chatJobId')

  if (
    (newData.is_profile_update_required || !newData.is_profile_completed) &&
    !newData?.is_job_preferences
  ) {
    if (isPhone) {
      sessionStorage.setItem('fromPhoneLogin', '1')
    } else {
      sessionStorage.removeItem('fromPhoneLogin')
    }
    const step = getProfileCompleteStep(newData) || 1

    const query = chatJobId ? `&from=chat&chatJobId=${chatJobId}` : ''

    if (chatJobId) {
      routes(`/${langKey}/jobseeker-complete-profile?step=${step}${query}`)
    } else {
      routes(`/${langKey}/jobseeker-complete-profile?step=1`)
    }
  } else if (isChatRedirect) {
    sessionStorage.removeItem('isChatRedirect')
    location.href = isChatRedirect
    // routes.push(isChatRedirect)
  } else if (defaultRedirectPage) {
    routes(defaultRedirectPage)
  } else if (redirectPath) {
    routes(redirectPath)
    removeCookie('__redirect')
  } else if (redirectPage) {
    sessionStorage.removeItem('redirectPage')
    const url = window?.location?.pathname
    if (url === redirectPage) {
      return window.location.reload()
    }
    routes(redirectPage)
  } else {
    if (pathname.indexOf('/get-started') > -1) {
      routes('/')
    } else {
      router.refresh()
    }
  }
}
const InProviders = () => {
  const dispatch = useDispatch()
  // receive remote module notifications
  const router = useRouter()
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const revoke = [
        receiveNotification('ROUTE_PUSH', (data) => {
          router.push(data.note)
        }),
        receiveNotification('SYSTEM_NOTETIFICATION', async (data) => {
          dispatch(displayNotification(data.note))
        }),
        receiveNotification('ON_LOGIN_SUCCESS', async (data) => {
          let { data: loginData, isPhone } = data.note
          const is_new_account = loginData?.is_new_account
          console.log('---loginData----', loginData)
          if (is_new_account) {
            traceDistinationAbility()
          }
          try {
            const userInfo = await fetchUserOwnDetailService()
            const userDetail = userInfo?.data?.data
            publishSharedData('ME', userDetail)
            loginData = { ...loginData, ...userDetail }
            await fetchUserSetting({ country_id: getCountryId(), language_id: getLanguageId() })
          } catch (error) {
            console.log('error', error)
          }
          defaultLoginCallBack(loginData, isPhone, router)
        })
      ].reduce(
        (prev, curr) => () => {
          prev?.()
          curr?.()
          // eslint-disable-next-line @typescript-eslint/no-empty-function
        },
        () => {}
      )

      return () => revoke?.()
    }
  }, [])
  return <></>
}
export default InProviders