import configuredAxios from "helpers/configuredAxios"
import { cache } from "react"

const fetchFindJobSearchItemData = cache(async (lang) => {
  const axios = await configuredAxios('apiGo')
  const data = await axios.get(`web/search_job_filter?lang=${lang}`)
    .then(res => res.data.data)
    .catch(error =>
      console.error("Get FindJobSearchItem Error:", error)
    )

  return data ?? {}
})

export {
  fetchFindJobSearchItemData
}