import configuredAxios from "helpers/configuredAxios";
import { serviceType } from "helpers/constants";
const log = process.env.NODE_ENV == 'development' ? console.log : () => void 0
export const COMPANY_VIEW_BURIED_COOKIE = 'view-company-buried'
export const JOB_VIEW_BURIED_COOKIE = 'view-job-buried'
const isObject = (obj: any) => typeof obj === 'object' && obj !== null
export async function reportTrackerEvent<T extends object>(
  type: serviceType,
  path: string,
  method: 'GET' | 'POST',
  eventData: T
) {
  try {
    if (!type || !eventData || !path) return
    const axios = configuredAxios(type)
    let result
    if (method === 'GET') {
      result = await axios.get(path, eventData)
        .then(res => res.data)
    } else {
      result = await axios.post(path, eventData)
    }
    // const result = await request(path, eventData)
    if (result?.data?.code != 0) {
      throw new Error(`report tracker error path: ${path}`);
    }
    log('report success');
  } catch (error) {
    console.error(error);
  }
}

// job view source tracker enums
export const viewJobSourceTracker = {
  home: 'job_reco_home', // home
  jobsHiring: 'job_search', // jobs hiring
  hotJobForLogin: 'job_recommended', // hot job recommend: login
  hotJobForUnLogin: 'job_popular', // hot job recommend: unLogin
  myJobsForRecommend: 'reco_job', // my jobs tab: recommend
  myJobsForLatest: 'reco_job_latest', // my jobs tab: latest
  remoteHome: 'remote_home', // remote home
  remoteJobsHiring: 'remote_job_search', // remote jobs hiring
  jobDetailSimilar: 'job_detail_similar', // job detail similar job
  hackquest: 'hackquest', // hackquest
} as const

// job count source tracker enums
export const jobCountSourceTracker = {
  home: 'home_load_complete', // home
  jobsHiring: 'job_search_event', // jobs hiring
  hotJobRecommend: 'recommend_job_event', // hot job recommend
  myJobs: 'my_jobs_event', // my jobs
  remoteHome: 'remote_home_load_complete', // remote home
  remoteJobsHiring: 'remote_job_search_event', // remote jobs hiring
} as const


// company view source tracker enums
export const viewCompanySourceTracker = {
  home: 'company_reco_home', // home
  jobsHiring: 'job_search', // jobs hiring
  hotJobRecommend: 'job_search_hot_jobs', // hot job recommend
  companies: 'company_search', // companies
  jobDetail: 'job_search_company_reco', // job detail
  myJobsForRecommend: 'reco_job', // my jobs tab: recommend
  myJobsForLatest: 'reco_job_latest', // my jobs tab: latest
  remoteHome: 'company_reco_remote_home', // remote home
  remoteJobsHiring: 'company_reco_remote', // remote jobs hiring
  remoteCompanies: 'company_search_remote', // remote companies
} as const

// view hr source tracker enums
export const viewHrSourceTracker = {
  companyDetail: 'company_detail_view_hr', // company detail
} as const


type ValueOf<T> = T[keyof T];

export const DeviceNameTrackerEnum = {
  web: 'web',
  mobile_web: 'mobile_web'
} as const

type DeviceNameTrackerType = keyof typeof DeviceNameTrackerEnum

export type ValueOfCompanySourceTracker = ValueOf<typeof viewCompanySourceTracker>

export type ValueOfJobSourceTracker = ValueOf<typeof viewJobSourceTracker>

interface ICompanyViewCookies {
  id: string | number
  payload: {
    source: ValueOfCompanySourceTracker
    device: DeviceNameTrackerType
    device_udid: string | null
    country_id?: string | number
    reco_from?: string | null
  }
}

interface IJobViewCookies {
  source: ValueOfJobSourceTracker
  device?: DeviceNameTrackerType
  device_udid?: string | null
  reco_from?: string | null
  is_login?: 0 | 1
  query_id?: string
}

/**
 * Removes empty values from the input object.
 *
 * @param {object} obj - the input object
 * @return {object} the object with null values removed
 */
export const cleanObjectValues = (obj: object) => {
  if (!isObject(obj)) return obj
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined && value !== '') {
      acc[key] = value
    }
    return acc
  }, {})
}

export const validateTrackerValues = (obj: object, value: string): boolean => {
  if (!isObject(obj) || !value) return false
  return Object.values(obj).includes(value)
}

/**
 * Validates the source and device values.
 *
 * @param {Object} obj - the object containing the source and device values
 * @param {string} source - the value of the source
 * @param {string} device - the value of the device
 * @return {boolean} true if both source and device values are valid, otherwise false
 */
export const validateSourceAndDevice = ({ obj, source, device }): boolean => {
  return validateTrackerValues(obj, source) && validateTrackerValues(DeviceNameTrackerEnum, device)
}

/**
 * Sets the job view cookies tracker.
 *
 * @param {Function} setCookie - The function used to set the cookies.
 * @param {IJobViewCookies} data - The data to be stored in the cookies.
 */
export const setJobViewCookiesTracker = (setCookie: Function, data: IJobViewCookies) => {
  try {
    if (!setCookie || !data || (typeof setCookie !== 'function')) return
    const newData = cleanObjectValues(data)
    setCookie && setCookie(JOB_VIEW_BURIED_COOKIE, JSON.stringify(newData))
  } catch (error) {
    console.log('set job view cookies tracker error', error)
  }
}

/**
 * Sets the company view cookies tracker.
 *
 * @param {Function} setCookie - The function used to set the cookies.
 * @param {ICompanyViewCookies} data - The data to be stored in the cookies.
 */
export const setCompanyViewCookiesTracker = (setCookie: Function, data: ICompanyViewCookies) => {
  try {
    if (!setCookie || !data || (typeof setCookie !== 'function')) return
    if (!data?.id) return
    const newData = cleanObjectValues(data)
    setCookie && setCookie(COMPANY_VIEW_BURIED_COOKIE, JSON.stringify(newData))
  } catch (error) {
    console.log('set company view cookies tracker error', error)
  }
}

/**
 * Returns the device name tracker based on the given isMobile flag.
 *
 * @param {boolean} isMobile - indicates whether the device is mobile or not
 * @return {string} 'web' or 'mobile_web'
 */
export const getDeviceNameTracker = (isMobile: boolean): DeviceNameTrackerType => {
  return isMobile ? DeviceNameTrackerEnum.mobile_web : DeviceNameTrackerEnum.web
}