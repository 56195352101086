'use client'
import React, { useState, useEffect, useRef } from 'react'
import { Flex } from 'antd'
import { getCountryId, getLang, getLanguageId } from 'helpers/country'
import queryString from 'query-string'

import styled from '@emotion/styled'
import { getCookie, redirectUrl, removeCookie, userKey } from 'helpers/cookies'
import { fetchUserSetting } from 'store/services/swtichCountry/userSetting'

const bossFriendLinks = [
  {
    linkNameKey: 'jpFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.jp`,
    countryKey: 'jp'
  },
  {
    linkNameKey: 'phFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.ph`,
    countryKey: 'ph'
  },
  {
    linkNameKey: 'sgFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.sg`,
    countryKey: 'sg'
  },
  {
    linkNameKey: 'myFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.my`,
    countryKey: 'my'
  },

  {
    linkNameKey: 'idFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.id`,
    countryKey: 'id'
  },
  {
    linkNameKey: 'hkFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.hk`,
    countryKey: 'hk'
  },
  {
    linkNameKey: 'twFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.tw`,
    countryKey: 'tw'
  },
  {
    linkNameKey: 'usFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.us`,
    countryKey: 'us'
  },
  {
    linkNameKey: 'gbFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.uk`,
    countryKey: 'gb'
  },
  {
    linkNameKey: 'caFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.ca`,
    countryKey: 'ca'
  },
  {
    linkNameKey: 'brFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.com.br`,
    countryKey: 'br'
  },
  {
    linkNameKey: 'trFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.com.tr`,
    countryKey: 'tr'
  },
  {
    linkNameKey: 'ngFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.ng`,
    countryKey: 'ng'
  },
  {
    linkNameKey: 'aeFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.ae`,
    countryKey: 'ae'
  },
  {
    linkNameKey: 'lkFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.lk`,
    countryKey: 'lk'
  },
  {
    linkNameKey: 'comFriendLink',
    link: `//${process.env.NEXT_PUBLIC_URL_PREFIX || ''}bossjob.com`,
    countryKey: 'com'
  }
]

const FriendLinks = (props: any) => {
  const { data, countryKey } = props
  const [isWrap, setIsWrap] = useState(true)
  const [showWrap, setShowWrap] = useState(false)
  const { more, less } = data?.foot || {}

  const filteredLinks = bossFriendLinks.filter((item) => item.countryKey !== countryKey)
  const friendlinksRef = useRef(null)

  useEffect(() => {
    // 获取 friendlinks 元素的宽度
    if (friendlinksRef.current) {
      const height = friendlinksRef.current.offsetHeight
      if (height > 33) {
        setIsWrap(false)
        setShowWrap(true)
      }
    }
  }, [])

  const handleToggleShowMore = () => {
    setIsWrap(!isWrap)
    setTimeout(() => {
      friendlinksRef.current.scrollIntoView({ block: 'end' })
    }, 300)
  }

  const handleSwitchNation = async (link) => {
    const languageId = getLanguageId()
    const countryId = getCountryId(link.countryKey)
    const { pathname } = window.location
    const { query } = queryString.parseUrl(window.location.href)
    const sessionid = getCookie('sessionid')
    removeCookie('location')

    const user = getCookie(userKey)
    const lang = getLang()

    if (sessionid) {
      try {
        await fetchUserSetting({ country_id: countryId, language_id: languageId })
      } catch (error) {
        console.log('error', error)
      }
    }

    const baseUrl = link?.link

    if (!sessionid) {
      const url = queryString.stringifyUrl({
        url: baseUrl + '/' + lang,
        query: query
      })
      window.location.href = url
      return
    }

    const queries = {
      sessionid,
      country: link?.countryKey,
      [userKey]: JSON.stringify(user),
      [redirectUrl]: pathname.split('/').slice(2).join('/'),
      ...query
    }

    const url = queryString.stringifyUrl({
      url: baseUrl + `/${lang}/changeLocale`,
      query: queries
    })

    window.location.href = url
  }

  const handleRedirect = (ev, link) => {
    ev.preventDefault()
    handleSwitchNation(link)
  }

  return (
    <FriendLinksStyle>
      <div className='friendlinks_wrap'>
        <div
          className='friendlinks'
          ref={friendlinksRef}
          style={{ height: isWrap ? 'auto' : '29px', overflowY: isWrap ? 'auto' : 'hidden' }}
        >
          <Flex wrap={'wrap'} gap={'small'}>
            {filteredLinks.map((link, index) => (
              <a
                key={index}
                className='friendlink'
                href={link.link + '/' + getLang()}
                target='_blank'
                onClick={(ev) => handleRedirect(ev, link)}
              >
                {data?.foot[link.linkNameKey] || ''}
              </a>
            ))}
          </Flex>
        </div>
        {showWrap ? (
          <span className='btn-more' onClick={() => handleToggleShowMore()}>
            <span className='btn-more-text'>{!isWrap ? more : less}</span>
            <span className='right-icon'>{!isWrap ? <DownIcon /> : <UpIcon />}</span>
          </span>
        ) : null}
      </div>
    </FriendLinksStyle>
  )
}

export default FriendLinks

const FriendLinksStyle = styled.div`
  background: #2b2b2b;
  .friendlinks_wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1136px;
    margin: 0 auto;
    padding: 14px 0;
    .friendlinks {
      flex: 1;
      margin-left: -12px;
      .friendlink {
        padding: 6px 12px;
        color: #7d7d7d;
        font-size: 14px;
        position: relative;
        &:hover {
          color: #ffffff;
        }
      }

      .friendlink:last-child::after {
        display: none;
      }
    }
    .btn-more {
      display: flex;
      justify-content: center;
      align-items: start;
      &:hover {
        cursor: pointer;
        .btn-more-text {
          color: #ffffff;
        }
        .right-icon {
          svg {
            path {
              fill: #ffffff;
            }
          }
        }
      }
      .btn-more-text {
        display: inline-block;
        margin-right: 10px;
        padding: 6px 0;
        font-size: 14px;
        color: #7d7d7d;
      }
      .right-icon {
        padding: 6px 0;
      }
    }
  }
`

const UpIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.19526 11.6903C1.93491 11.4299 1.93491 11.0078 2.19526 10.7475L7.5286 5.41412C7.78895 5.15378 8.21106 5.15378 8.4714 5.41412L13.8047 10.7475C14.0651 11.0078 14.0651 11.4299 13.8047 11.6903C13.5444 11.9506 13.1223 11.9506 12.8619 11.6903L8 6.82834L3.13807 11.6903C2.87772 11.9506 2.45561 11.9506 2.19526 11.6903Z'
        fill='#7D7D7D'
      />
    </svg>
  )
}

const DownIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.8047 5.4141C14.0651 5.67445 14.0651 6.09656 13.8047 6.35691L8.4714 11.6902C8.21105 11.9506 7.78894 11.9506 7.5286 11.6902L2.19526 6.35691C1.93491 6.09656 1.93491 5.67445 2.19526 5.4141C2.45561 5.15375 2.87772 5.15375 3.13807 5.4141L8 10.276L12.8619 5.4141C13.1223 5.15375 13.5444 5.15375 13.8047 5.4141Z'
        fill='#7D7D7D'
      />
    </svg>
  )
}
