'use client'
import { FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'
import { flatMap, identity, isEqual } from 'lodash-es'
import JobItem from './item'
import { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { fetchFindJobSearchItemData } from './service'
import { getLang } from 'helpers/country'
import classNames from 'classnames'

const BenefitsMultiSelector = (props: any) => {
  const {
    label,
    className,
    value,
    isTouched,
    onChange,
    lang,
    benefits_filter,
    containerClassName = null,
    ...rest
  } = props
  const [showModal, setShowModal] = useState(false)
  const [firstRender, setFirstRender] = useState(true)
  const [activeFirst, setActiveFirst] = useState<any>()
  const [activeSecond, setActiveSecond] = useState<any>()

  const [benefitList, setBenefitList] = useState<any[]>([])
  useEffect(() => {
    const fetchData = async () => {
      const language = getLang() || 'en-us'
      const config = await fetchFindJobSearchItemData(language)
      setBenefitList((config as any)?.benefits_filter ?? [])
    }

    if (benefits_filter?.length) {
      setBenefitList(benefits_filter)
    } else {
      fetchData()
    }
  }, [benefits_filter])

  const secondList = useMemo(() => {
    const list = activeFirst?.children.sort((a, b) => Number(a.id) - Number(b.id))
    return [{ value: 'All', id: -1, parent: activeFirst }].concat(list ?? [])
  }, [activeFirst])

  const [mainFunctions, setMainfunctions] = useState(value?.mainFunctions ?? [])
  const [functionIds, setFunctionIds] = useState(value?.jobFunctions ?? [])

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'auto'
      return
    }
    if (showModal) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
      setActiveFirst(null)
      setActiveSecond(null)
    }
  }, [showModal, isMobile])

  useEffect(() => {
    if (
      !isEqual(value, {
        mainFunctions: mainFunctions,
        jobFunctions: functionIds
      })
    ) {
      setMainfunctions(value?.mainFunctions ?? [])
      setFunctionIds(value?.jobFunctions ?? [])
    }
  }, [value])

  const preShowModal = useRef(false)

  useEffect(() => {
    const ids = []

    if (mainFunctions?.length) {
      mainFunctions.filter((grandpa) => {
        const list = benefitList.find((parent) => parent.seo_value === grandpa)

        if (list) {
          list.benefits.filter((child) => {
            ids.push(child.id)
          })
        }
      })
    }

    if (functionIds?.length) {
      functionIds.filter((grandpa) => {
        benefitList.filter((parent) => {
          const benefit = parent.benefits.find((child) => child.seo_value === grandpa)
          if (benefit) {
            ids.push(benefit.id)
          }
        })
      })
    }

    if (!showModal && preShowModal.current && !isMobile) {
      onChange?.({
        mainFunctions: mainFunctions,
        jobFunctions: functionIds,
        ids
      })

      console.log({
        mainFunctions: mainFunctions,
        jobFunctions: functionIds,
        ids
      })
    }
    preShowModal.current = showModal
  }, [showModal, benefitList])

  const formattedJobfunctions = useMemo(() => {
    return benefitList.map((obj, index) => {
      const firstParent = {
        value: obj.category_value,
        id: index,
        seo_value: obj.seo_value,
        key: obj.seo_value,
        children: undefined
      }

      firstParent.children = obj.benefits.map((second) => {
        const secondParent = {
          id: second.id,
          value: second.name,
          seo_value: second['seo_value'],
          key: second['seo_value'],
          parent: firstParent,
          children: undefined
        }

        return secondParent
      })

      return firstParent
    })
  }, [benefitList])

  const allSeconds = useMemo(
    () => flatMap(formattedJobfunctions, (item) => item.children),
    [formattedJobfunctions]
  )
  const allThirds = useMemo(() => flatMap(allSeconds, (item) => item.children), [allSeconds])
  const textValue = useMemo(() => {
    return (
      mainFunctions
        .map((key) => formattedJobfunctions.find((item) => item.seo_value === key)?.value)
        .concat(functionIds.map((key) => allSeconds.find((item) => item.seo_value === key)?.value))

        .filter(identity)
        .join(',') ?? ''
    )
  }, [mainFunctions, functionIds, allSeconds, allThirds, formattedJobfunctions])

  const isSecondSelected = useCallback(
    (second) => {
      if (second.id === -1) {
        return mainFunctions.includes(second.parent.seo_value)
      }
      if (mainFunctions.includes(second.parent.seo_value)) {
        return false
      }
      return functionIds.includes(second.seo_value)
    },
    [functionIds, mainFunctions]
  )

  const isFirstSelected = useCallback(
    (first) => {
      return (
        mainFunctions.includes(first.seo_value) ||
        first.children.filter(isSecondSelected).length > 0
      )
    },
    [mainFunctions, isSecondSelected]
  )

  const onFirstClick = useCallback(
    (first) => {
      if (mainFunctions.includes(first.seo_value)) {
        setMainfunctions(mainFunctions.filter((key) => key !== first.seo_value))
      } else {
        setMainfunctions([first.seo_value, ...mainFunctions])
        const selectedSecondIds = first.children.map((second) => second.seo_value)
        setFunctionIds(functionIds.filter((key) => !selectedSecondIds.includes(key)))
      }
    },
    [mainFunctions, functionIds]
  )

  const onSecondClick = useCallback(
    (second) => {
      if (second.id === -1) {
        onFirstClick(second.parent)
      } else {
        if (mainFunctions.includes(second.parent.seo_value)) {
          setMainfunctions(mainFunctions.filter((value) => value !== second.parent.seo_value))
        }
        if (functionIds.includes(second.seo_value)) {
          setFunctionIds(functionIds.filter((key) => key !== second.seo_value))
        } else {
          const newSelected = [
            second.seo_value,
            ...functionIds.filter((key) =>
              second.parent.children.map((item) => item.seo_value).includes(key)
            )
          ]
          if (newSelected.length === second.parent.children.length) {
            onFirstClick(second.parent)
          } else {
            setFunctionIds([second.seo_value, ...functionIds])
          }
        }
      }
    },
    [onFirstClick, functionIds, mainFunctions]
  )

  const listener = useCallback((e) => {
    if (e.target.id?.includes?.('job-item')) {
      return
    }
    setShowModal(false)
    document.removeEventListener('click', listener)
  }, [])

  useEffect(() => {
    if (!isMobile) {
      if (showModal) {
        setTimeout(() => {
          document.addEventListener('click', listener)
        }, 1000)
      } else {
        document.removeEventListener('click', listener)
      }
      return () => document.removeEventListener('click', listener)
    }
  }, [showModal])

  const onSecondHover = useCallback((hoverThirdList) => {
    setActiveSecond(hoverThirdList)
  }, [])
  const onFirstHover = useCallback(
    (hoverSecondList) => {
      if (firstRender) {
        setFirstRender(false)
      }
      if (activeFirst?.seo_value !== hoverSecondList.seo_value) {
        setActiveFirst(hoverSecondList)
        setActiveSecond(undefined)
      }
    },
    [activeFirst]
  )

  const valueText = useMemo(() => {
    const selected = textValue.split(',').filter((a) => a)
    const length = selected?.length

    return `${label} ${length ? `(${length})` : ''}`
  }, [textValue, value])

  return (
    <FormControl className={className} size='small'>
      {showModal && <div className={styles.mask} onClick={listener}></div>}
      <TextField
        value={valueText}
        autoComplete='off'
        label={<div style={{ position: 'relative', bottom: 7 }}>{label}</div>}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setShowModal(true)
        }}
        classes={{}}
        style={{
          background: textValue.split(',').filter((a) => a)?.length ? '#E7F1FB' : '#F0F0F0'
        }}
        inputProps={{
          readOnly: true,
          style: {
            color: textValue.split(',').filter((a) => a)?.length ? '#136FD3' : 'rgba(0, 0, 0, 0.6)',

            background: textValue.split(',').filter((a) => a)?.length ? '#E7F1FB' : '#F0F0F0'
          }
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='11'
                  height='7'
                  viewBox='0 0 11 7'
                  fill='none'
                >
                  <path
                    d='M9.46298 0.966675C10.3706 0.966675 10.8088 2.07844 10.1453 2.69773L6.18232 6.39651C5.79811 6.7551 5.20189 6.7551 4.81768 6.39651L0.854703 2.69773C0.191182 2.07844 0.629399 0.966674 1.53702 0.966674L9.46298 0.966675Z'
                    fill='#86909C'
                  />
                </svg>
              </IconButton>
            </InputAdornment>
          )
        }}
        disabled={showModal}
        onFocus={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (!isTouched) {
            setShowModal(true)
          }

          rest?.onFocus?.(e)
          Promise.resolve().then(() => {
            e.target.blur()
          })
        }}
        {...rest}
      />
      {showModal && (
        <div
          className={classNames([styles.container], containerClassName)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onScroll={(e) => {
            e.stopPropagation()
            e.preventDefault()
          }}
        >
          <div
            className={styles.column}
            onScroll={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            {formattedJobfunctions.map((first) => {
              return (
                <JobItem
                  key={first.value}
                  data={first}
                  active={activeFirst?.seo_value === first.seo_value}
                  checked={isFirstSelected(first)}
                  onMouseOver={() => onFirstHover(first)}
                  onClick={() => onFirstClick(first)}
                />
              )
            })}
          </div>
          {secondList.length > 1 && (
            <div
              className={styles.column}
              onScroll={(e) => {
                e.stopPropagation()
                e.preventDefault()
              }}
            >
              {secondList.map((second: any) => {
                return (
                  <JobItem
                    key={second.id}
                    data={second}
                    active={activeSecond?.seo_value === second.seo_value}
                    checked={isSecondSelected(second)}
                    onMouseOver={() => onSecondHover(second)}
                    onClick={() => {
                      onSecondClick(second)
                    }}
                    noArrow={true}
                  />
                )
              })}
            </div>
          )}
        </div>
      )}
    </FormControl>
  )
}

export default BenefitsMultiSelector
