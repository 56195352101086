'use client'
import React from 'react'

import Script from 'next/script'
import { getCountryKey } from 'helpers/country'
import { gtagIdMap } from 'helpers/country.base'

const isDev = process.env.ENV === 'development'
const isProd = process.env.ENV === 'production'

const GoogleSlot = () => {
  const countryKey = getCountryKey()
  const Id = gtagIdMap[countryKey]
  const isJp = countryKey === 'jp'

  if (isJp) {
    return (
      <>
        <Script defer src={`https://www.googletagmanager.com/gtag/js?id=${Id}`} />
        <Script
          id='gtag-init'
          defer
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${Id}');
      gtag('config', 'AW-844310282', { 'allow_enhanced_conversions': true });
      var isInternalTraffic = ${isDev};
      if (isInternalTraffic) {
        gtag('set', {'traffic_type': 'internal'});
      } else {
        gtag('set', {'traffic_type': 'external'});
      };
      `
          }}
        />
        <Script>
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', 'GTM-WDMX2B3D')
        `}
        </Script>
      </>
    )
  }

  return (
    <>
      <Script defer src={`https://www.googletagmanager.com/gtag/js?id=${Id}`} />
      <Script
        id='gtag-init'
        defer
        dangerouslySetInnerHTML={{
          __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${Id}');
      gtag('config', 'AW-844310282', { 'allow_enhanced_conversions': true });
      var isInternalTraffic = ${isDev};
      if (isInternalTraffic) {
        gtag('set', {'traffic_type': 'internal'});
      } else {
        gtag('set', {'traffic_type': 'external'});
      };
      `
        }}
      />
      {isProd && (
        <>
          <Script defer src='https://www.googletagmanager.com/gtag/js?id=AW-16806505821' />
          <Script
            id='gtag-special'
            defer
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || []; 
          function gtag(){
          console.log('@james:', '!!!!!!!!!')
            window.dataLayer.push(arguments);
          }
           gtag('js', new Date());
           gtag('config', 'AW-16806505821');
          `
            }}
          />
        </>
      )}
    </>
  )
}

export default GoogleSlot
