'use client'
import React, { useEffect, useState, useMemo } from 'react'
import Link from 'components/Link'
import Text from 'components/Text'
import classNames from 'classnames'
import { usePathname } from 'next/navigation'
import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { getCookie } from 'helpers/cookies'

import { FindJobsSvg } from 'images/svg'
import RemoteJobsSvg from 'images/icons/remoteJobs.svg'
import OnsiteJobsSvg from 'images/icons/onSiteJobs.svg'
import RecommendedJobsSvg from 'images/icons/recommendedJobs.svg'
import RemoteJobsSvgOn from 'images/icons/remoteJobs_on.svg'
import OnsiteJobsSvgOn from 'images/icons/onSiteJobs_on.svg'
import Web3Svg from 'images/icons/web3.svg'
import Web3SvgOn from 'images/icons/web3_on.svg'
import RecommendedJobsSvgOn from 'images/icons/recommendedJobs_on.svg'
import arrowSvg from 'images/icons/arrow.svg'

import styles from '../Header.module.scss'
import { getCountryKey } from 'helpers/country'

interface IProps {
  langKey: string
  lang: any
}

const setHandleWidth = (langKey, isLogin) => {
  if (langKey == 'ja') {
    return [1300, 1200, 1000]
  } else if (langKey == 'ms') {
    return [1420, 1340, 1150]
  } else if (langKey == 'zh-cn' || langKey == 'zh-tw') {
    return [1070, 1010, 900]
  }
  if (!isLogin) {
    return [1250, 1170, 1040]
  }
  return [1220, 1130, 951]
}

const NavLeft = (props: IProps) => {
  const { langKey, lang } = props
  const pathname = usePathname()
  const isLogin = getCookie('sessionid') ? true : false
  const [countryKey, setCountryKey] = useState('ph')
  const [currentMenu, setCurrentMenu] = useState('')
  const [hideNumber, setHideNumber] = useState(0)
  const hideLevelOnWidth = setHandleWidth(langKey, isLogin)
  useEffect(() => {
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [])

  const {
    home,
    careerGuide,
    companies,
    findJobs,
    remoteJobs,
    onSiteJobs,
    recommendedJobs,
    workInAnyCountryOrRegion,
    jobsInYourCountryOrRegion,
    aiRecommendsJobsBasedOnYourPreferences,
    startGuide,
    Web3Jobs,
    Web3Describe
  } = lang || {}

  const mutableContentItems = useMemo(
    () => [
      {
        key: '3',
        label: (
          <Link title='' to={'/' + langKey + (countryKey === 'jp' ? '/talent' : '/talents')} aTag>
            <div className={styles.children_nav}>
              <div className={styles.children_nav_short}>
                <div className={styles.children_nav_title}> {startGuide}</div>
              </div>
            </div>
          </Link>
        )
      },
      {
        key: '2',
        label: (
          <Link
            title='Career Guide'
            to={countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'}
            external
          >
            <div className={styles.children_nav}>
              <div className={styles.children_nav_short}>
                <div className={styles.children_nav_title}>{careerGuide}</div>
              </div>
            </div>
          </Link>
        )
      },
      {
        key: '1',
        label: (
          <Link title='RemoteJobs' to={'/' + langKey + '/remote-jobs-hiring/jobs'}>
            <div className={styles.children_nav}>
              <div className={styles.children_nav_short}>
                <div className={styles.children_nav_title}>{remoteJobs}</div>
              </div>
            </div>
          </Link>
        )
      }
    ],
    [langKey, countryKey]
  )

  const onResize = () => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth
      if (width > hideLevelOnWidth[0]) {
        setHideNumber(0)
      } else if (width > hideLevelOnWidth[1]) {
        setHideNumber(1)
      } else if (width > hideLevelOnWidth[2]) {
        setHideNumber(2)
      } else {
        setHideNumber(3)
      }
    }
  }
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      onResize()
    })
    resizeObserver.observe(document.body)
  }, [])

  useEffect(() => {
    const countryKey = getCountryKey()
    setCountryKey(countryKey)
  }, [])

  useEffect(() => {
    if (/(.*)\/my-jobs(.*)$/.test(pathname)) {
      setCurrentMenu('recommended')
    } else if (/(.*)\/jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('onsite')
    } else if (/(.*)\/remote-jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('remote')
    } else if (/(.*)\/web3-jobs-hiring(.*)$/.test(pathname)) {
      setCurrentMenu('web3')
    } else {
      setCurrentMenu('')
    }
  }, [pathname])

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link
          title='RemoteJobs'
          to={'/' + langKey + '/remote-jobs-hiring/jobs'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_remote'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <img
                src={currentMenu === 'remote' ? RemoteJobsSvgOn : RemoteJobsSvg}
                alt='Remote Jobs'
              />
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{remoteJobs}</div>
              <p>{workInAnyCountryOrRegion}</p>
            </div>

            <img src={arrowSvg} alt='arrow' className={styles.childrenArrow} />
            <svg
              className={styles.checkedIcon}
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M14.2745 4.44608L6.99502 12.608C6.87081 12.7473 6.69391 12.8281 6.50732 12.8309C6.32073 12.8336 6.14153 12.758 6.01326 12.6225L1.72412 8.09002L2.69257 7.17357L6.48295 11.179L13.2794 3.55859L14.2745 4.44608Z'
                fill='#2378E5'
              />
            </svg>
          </div>
        </Link>
      )
    },
    {
      key: '2',
      label: (
        <Link
          title='OnSiteJobs'
          to={'/' + langKey + '/jobs-hiring/job-search'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_onsite'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <img
                src={currentMenu === 'onsite' ? OnsiteJobsSvgOn : OnsiteJobsSvg}
                alt='Remote Jobs'
              />
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{onSiteJobs}</div>
              <p>{jobsInYourCountryOrRegion}</p>
            </div>

            <img src={arrowSvg} alt='arrow' className={styles.childrenArrow} />
            <svg
              className={styles.checkedIcon}
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M14.2745 4.44608L6.99502 12.608C6.87081 12.7473 6.69391 12.8281 6.50732 12.8309C6.32073 12.8336 6.14153 12.758 6.01326 12.6225L1.72412 8.09002L2.69257 7.17357L6.48295 11.179L13.2794 3.55859L14.2745 4.44608Z'
                fill='#2378E5'
              />
            </svg>
          </div>
        </Link>
      )
    },
    {
      key: '4',
      label: (
        <Link
          title='web3Jobs'
          to={'/' + langKey + '/web3-jobs-hiring/job-search'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_web3'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <img src={currentMenu === 'web3' ? Web3SvgOn : Web3Svg} alt='web3 Jobs' />
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{Web3Jobs}</div>
              <p>{Web3Describe}</p>
            </div>

            <img src={arrowSvg} alt='arrow' className={styles.childrenArrow} />
            <svg
              className={styles.checkedIcon}
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M14.2745 4.44608L6.99502 12.608C6.87081 12.7473 6.69391 12.8281 6.50732 12.8309C6.32073 12.8336 6.14153 12.758 6.01326 12.6225L1.72412 8.09002L2.69257 7.17357L6.48295 11.179L13.2794 3.55859L14.2745 4.44608Z'
                fill='#2378E5'
              />
            </svg>
          </div>
        </Link>
      )
    },
    isLogin && {
      key: '3',
      label: (
        <Link
          title='RecommendedJobs'
          to={'/' + langKey + '/my-jobs?page=1'}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_find_jobs_recommend'
          })}
        >
          <div className={styles.children_nav}>
            <div className={styles.children_nav_icon}>
              <img
                src={currentMenu === 'recommended' ? RecommendedJobsSvgOn : RecommendedJobsSvg}
                alt='Remote Jobs'
              />
            </div>

            <div className={styles.children_nav_short}>
              <div className={styles.children_nav_title}>{recommendedJobs}</div>
              <p>{aiRecommendsJobsBasedOnYourPreferences}</p>
            </div>

            <img src={arrowSvg} alt='arrow' className={styles.childrenArrow} />
            <svg
              className={styles.checkedIcon}
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='17'
              fill='none'
            >
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M14.2745 4.44608L6.99502 12.608C6.87081 12.7473 6.69391 12.8281 6.50732 12.8309C6.32073 12.8336 6.14153 12.758 6.01326 12.6225L1.72412 8.09002L2.69257 7.17357L6.48295 11.179L13.2794 3.55859L14.2745 4.44608Z'
                fill='#2378E5'
              />
            </svg>
          </div>
        </Link>
      )
    }
  ]

  const items2: MenuProps['items'] = useMemo(() => {
    switch (hideNumber) {
      case 0:
        return []
      case 1:
        return [mutableContentItems[2]]
      case 2:
        return [mutableContentItems[1], mutableContentItems[2]]
      case 3:
        return mutableContentItems
      default:
        return mutableContentItems
    }
  }, [hideNumber])

  const showMenu = (key, items) => {
    if (items.length == 0) {
      return true
    }
    return !items.some((e) => e.key == key)
  }

  return (
    <ul className={styles.headerLinksList}>
      <React.Fragment>
        <li
          className={styles.headerLink}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_home'
          })}
        >
          {pathname != '/' + langKey && pathname != '/' && pathname != '/en-us' ? (
            <Link title='Home' to={'/' + langKey}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {home}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {home}
            </Text>
          )}
        </li>
        <li className={styles.headerLink}>
          <Dropdown
            menu={{ items }}
            overlayClassName={classNames({
              [styles.findJobDownMenu]: true,
              [styles.remoteMenu]: currentMenu === 'remote',
              [styles.onsiteMenu]: currentMenu === 'onsite',
              [styles.web3Menu]: currentMenu === 'web3',
              [styles.recommendedMenu]: currentMenu === 'recommended'
            })}
          >
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerFindJob])}
            >
              {findJobs}
              <FindJobsSvg />
            </Text>
          </Dropdown>
        </li>
        <li
          className={styles.headerLink}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_company'
          })}
        >
          {!pathname.includes('/companies') ? (
            <Link title='Companies' to={'/' + langKey + '/companies'}>
              <Text textStyle='lg' className={styles.headerLinkText}>
                {companies}
              </Text>
            </Link>
          ) : (
            <Text
              textStyle='lg'
              className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
            >
              {companies}
            </Text>
          )}
        </li>
        {showMenu('3', items2) && (
          <li
            className={styles.headerLink}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_app'
            })}
          >
            {!pathname.includes('/talent') ? (
              <Link
                title=''
                to={'/' + langKey + (countryKey === 'jp' ? '/talent' : '/talents')}
                aTag
              >
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {startGuide}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {startGuide}
              </Text>
            )}
          </li>
        )}

        {showMenu('2', items2) && (
          <li
            className={styles.headerLink}
            style={{ position: 'relative' }}
            {...(pathname.endsWith('/remote-jobs') && {
              'data-gtag-event': 'rl_active_career_guide'
            })}
          >
            <Link
              title='Career Guide'
              to={countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'}
              external
            >
              <Text textStyle='lg' className={styles.headerLinkText}>
                {careerGuide}
              </Text>
            </Link>
          </li>
        )}

        {showMenu('1', items2) && (
          <li className={styles.headerLink}>
            <div className={styles.remoteBackground}></div>
            {!pathname.includes('/remote-jobs') || pathname.includes('/remote-jobs-hiring') ? (
              <Link title='remote Jobs' to={'/' + langKey + '/remote-jobs'}>
                <Text textStyle='lg' className={styles.headerLinkText}>
                  {remoteJobs}
                </Text>
              </Link>
            ) : (
              <Text
                textStyle='lg'
                className={classNames([styles.headerLinkText, styles.headerLinkTextCurrentPage])}
              >
                {remoteJobs}
              </Text>
            )}
          </li>
        )}

        {items2?.length > 0 ? (
          <li className={styles.headerLink}>
            <Dropdown
              menu={{ items: items2 }}
              overlayClassName={classNames({
                [styles.findJobDownMenu]: true
              })}
            >
              <div style={{ cursor: 'pointer' }}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='23'
                  height='22'
                  viewBox='0 0 23 22'
                  fill='none'
                >
                  <path
                    d='M7.36523 11C7.36523 11.7594 6.74963 12.375 5.99023 12.375C5.23084 12.375 4.61523 11.7594 4.61523 11C4.61523 10.2406 5.23084 9.625 5.99023 9.625C6.74963 9.625 7.36523 10.2406 7.36523 11Z'
                    fill='#121212'
                  />
                  <path
                    d='M13.2318 11C13.2318 11.7594 12.6162 12.375 11.8568 12.375C11.0974 12.375 10.4818 11.7594 10.4818 11C10.4818 10.2406 11.0974 9.625 11.8568 9.625C12.6162 9.625 13.2318 10.2406 13.2318 11Z'
                    fill='#121212'
                  />
                  <path
                    d='M17.7234 12.375C18.4828 12.375 19.0984 11.7594 19.0984 11C19.0984 10.2406 18.4828 9.625 17.7234 9.625C16.964 9.625 16.3484 10.2406 16.3484 11C16.3484 11.7594 16.964 12.375 17.7234 12.375Z'
                    fill='#121212'
                  />
                </svg>
              </div>
            </Dropdown>
          </li>
        ) : null}
      </React.Fragment>
    </ul>
  )
}

export default NavLeft
